<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2 ml-1">
        <strong>Periode</strong>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
        <a-month-picker
          placeholder="Start month"
          format="MM-YYYY"
          class=""
          ref="endYear"
          :default-value="inittgl1"
          @change="
            (dateMoment, dateString) => onChange(dateMoment, dateString, 'tgl1')
          "
        />
        <a-month-picker
          placeholder="End month"
          style="margin-left: 10px;"
          format="MM-YYYY"
          class="mr-2"
          :default-value="inittgl2"
          @change="
            (dateMoment, dateString) => onChange(dateMoment, dateString, 'tgl2')
          "
        />
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <strong>Laba/Rugi</strong>
                  </h5>
                  <div class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <a-select
                      show-search
                      class="ant-input col-sm-4 col-md-4 col-lg-4 float-right"
                      style="margin-left: 15px;"
                      v-model="select.labarugi"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.labarugi)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datalabarugi"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartLabaRugi"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-8 col-md-8 col-lg-8 mb-4">
                    <strong
                      >Jumlah Nasabah Tabungan Wadiah dan Dana Investasi</strong
                    >
                  </h5>
                  <div class="col-sm-4 col-md-4 col-lg-4 mb-4">
                    <a-select
                      show-search
                      class="ant-input col-sm-8 col-md-8 col-lg-8 float-right"
                      style="margin-left: 15px;"
                      v-model="select.jumlahtabungan"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.jumlahtabungan)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datajumlahtabungan"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="mb-5">
                  <bar-chart
                    :key="componentKey"
                    :chartdata="dataChartJumlahTabungan"
                    :options="barOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-8 col-md-8 col-lg-8 mb-4">
                    <strong
                      >Total Nominal Tabungan Wadiah dan Dana Investasi</strong
                    >
                  </h5>
                  <div class="col-sm-4 col-md-4 col-lg-4 mb-4">
                    <a-select
                      show-search
                      class="ant-input col-sm-12 col-md-12 col-lg-12 float-right"
                      style="margin-left: 15px;"
                      placeholder="Select Filter By?"
                      v-model="select.totaltabungan"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.totaltabungan)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datatotaltabungan"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartTotalTabungan"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-8 col-md-8 col-lg-8 mb-4">
                    <strong
                      >Total Aset Tetap Inventaris dan Akumulasi Amortisasi dan
                      Cadangan Penurunan Nilai</strong
                    >
                  </h5>
                  <div class="col-sm-4 col-md-4 col-lg-4 mb-4">
                    <a-select
                      show-search
                      class="ant-input col-sm-12 col-md-12 col-lg-12 float-right"
                      style="margin-left: 15px;"
                      placeholder="Select Filter By?"
                      v-model="select.totalaset"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.totalaset)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datatotalaset"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartTotalAset"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
        <div class="row">
          <h5 class="col-sm-6 col-md-6 col-lg-6 mb-2">
            <strong> Jumlah Data Pembiayaan </strong>
          </h5>
          <div class="col-sm-6 col-md-6 col-lg-6 mb-2">
            <a-select
              show-search
              class="ant-input col-sm-4 col-md-4 col-lg-4 float-right"
              style="margin-left: 15px;"
              v-model="select.pembiayaan"
              @change="
                (e) =>
                  pilihsandikantorpembiayaan(e)
              "
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(item, index) in datapembiayaanmurabahah"
                :key="index"
                :value="index"
              >
                {{ item.sandikc }}
              </a-select-option>
            </a-select>
            <label for="sandikantor" class="float-right mt-2"
              >Sandi Kantor:
            </label>
          </div>
        </div>
        <!-- <a-divider type="horizontal"/> -->
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h5 class="mb-4">
                  <strong>Jumlah Data Murabahah</strong>
                </h5>
                <div class="mb-5">
                  <bar-chart
                    :key="componentKey"
                    :chartdata="dataChartPembiayaanmurabahah"
                    :options="barOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h5 class="mb-4">
                  <strong>Jumlah Data Multijasa</strong>
                </h5>
                <div class="mb-5">
                  <bar-chart
                    :key="componentKey"
                    :chartdata="dataChartPembiayaanmultijasa"
                    :options="barOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h5 class="mb-4">
                  <strong>Jumlah Data Qardh</strong>
                </h5>
                <div class="mb-5">
                  <bar-chart
                    :key="componentKey"
                    :chartdata="dataChartPembiayaanqardh"
                    :options="barOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h5 class="mb-4">
                  <strong>Jumlah Data Bagi Hasil</strong>
                </h5>
                <div class="mb-5">
                  <bar-chart
                    :key="componentKey"
                    :chartdata="dataChartPembiayaanbagihasil"
                    :options="barOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h5 class="mb-4">
                  <strong>Jumlah Data Sewa</strong>
                </h5>
                <div class="mb-5">
                  <bar-chart
                    :key="componentKey"
                    :chartdata="dataChartPembiayaansewa"
                    :options="barOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
        <div class="row">
          <h5 class="col-sm-6 col-md-6 col-lg-6 mb-2">
            <strong> Total Jumlah dan Data Pembiayaan </strong>
          </h5>
          <div class="col-sm-6 col-md-6 col-lg-6 mb-2">
            <a-select
              show-search
              class="ant-input col-sm-4 col-md-4 col-lg-4 float-right"
              style="margin-left: 15px;"
              v-model="select.nominalpembiayaan"
              @change="
                (e) => pilihsandikantornominalpembiayaan(e)
              "
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(item, index) in datanominalpembiayaanmurabahah"
                :key="index"
                :value="index"
              >
                {{ item.sandikc }}
              </a-select-option>
            </a-select>
            <label for="sandikantor" class="float-right mt-2"
              >Sandi Kantor:
            </label>
          </div>
        </div>
        <!-- <a-divider type="horizontal"/> -->
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h6 class="mb-4">
                  <strong>Total Jumlah Murabahah</strong>
                </h6>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartNominalPembiayaanmurabahah"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h6 class="mb-4">
                  <strong>Total Jumlah Multijasa</strong>
                </h6>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartNominalPembiayaanmultijasa"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h6 class="mb-4">
                  <strong>Total Jumlah Qardh</strong>
                </h6>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartNominalPembiayaanqardh"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h6 class="mb-4">
                  <strong>Total Jumlah Bagi Hasil</strong>
                </h6>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartNominalPembiayaanbagihasil"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h6 class="mb-4">
                  <strong>Total Jumlah Sewa</strong>
                </h6>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartNominalPembiayaansewa"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from './component/linechart'
import BarChart from './component/barchart'
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  components: {
    'line-chart': LineChart,
    BarChart,
  },
  data() {
    return {
      datenow: null,
      tgl1: null,
      tgl2: null,
      inittgl1: null,
      inittgl2: null,
      select: {
        labarugi: '',
        pembiayaan: '',
        jumlahtabungan: '',
        totaltabungan: '',
        totalaset: '',
        nominalpembiayaan: '',
      },
      dataChartOptions: {
        labarugi: {
          fromarray: 'datalabarugi',
          toarray: 'dataChartLabaRugi',
          selectname: 'labarugi',
          fill: false,
          dynamicColor: false,
          bgcolorOpacity: '0',
          bordercolorOpacity: '1',
        },
        jumlahtabungan: {
          fromarray: 'datajumlahtabungan',
          toarray: 'dataChartJumlahTabungan',
          selectname: 'jumlahtabungan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        totaltabungan: {
          fromarray: 'datatotaltabungan',
          toarray: 'dataChartTotalTabungan',
          selectname: 'totaltabungan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        totalaset: {
          fromarray: 'datatotalaset',
          toarray: 'dataChartTotalAset',
          selectname: 'totalaset',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },

        // Pembiayaan
        pembiayaanmurabahah: {
          fromarray: 'datapembiayaanmurabahah',
          toarray: 'dataChartPembiayaanmurabahah',
          selectname: 'pembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        pembiayaanmultijasa: {
          fromarray: 'datapembiayaanmultijasa',
          toarray: 'dataChartPembiayaanmultijasa',
          selectname: 'pembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        pembiayaanqardh: {
          fromarray: 'datapembiayaanqardh',
          toarray: 'dataChartPembiayaanqardh',
          selectname: 'pembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        pembiayaanbagihasil: {
          fromarray: 'datapembiayaanbagihasil',
          toarray: 'dataChartPembiayaanbagihasil',
          selectname: 'pembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        pembiayaansewa: {
          fromarray: 'datapembiayaansewa',
          toarray: 'dataChartPembiayaansewa',
          selectname: 'pembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        // End pembiayaan

        /* Nominal Pembiayaan */
        nominalpembiayaanmurabahah: {
          fromarray: 'datanominalpembiayaanmurabahah',
          toarray: 'dataChartNominalPembiayaanmurabahah',
          selectname: 'nominalpembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        nominalpembiayaanmultijasa: {
          fromarray: 'datanominalpembiayaanmultijasa',
          toarray: 'dataChartNominalPembiayaanmultijasa',
          selectname: 'nominalpembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        nominalpembiayaanqardh: {
          fromarray: 'datanominalpembiayaanqardh',
          toarray: 'dataChartNominalPembiayaanqardh',
          selectname: 'nominalpembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        nominalpembiayaanbagihasil: {
          fromarray: 'datanominalpembiayaanbagihasil',
          toarray: 'dataChartNominalPembiayaanbagihasil',
          selectname: 'nominalpembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        nominalpembiayaansewa: {
          fromarray: 'datanominalpembiayaansewa',
          toarray: 'dataChartNominalPembiayaansewa',
          selectname: 'nominalpembiayaan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '1',
          bordercolorOpacity: '1',
        },
        /* End Nominal Pembiayaan */
      },
      componentKey: 0,
      datalabarugi: [],
      datajumlahtabungan: [],
      datatotaltabungan: [],
      datatotalaset: [],

      // Pembiayaan
      datapembiayaanmurabahah: [],
      datapembiayaanmultijasa: [],
      datapembiayaanqardh: [],
      datapembiayaanbagihasil: [],
      datapembiayaansewa: [],
      // End Pembiayaan

      /* Nominal Pembiayaan */
      datanominalpembiayaanmurabahah: [],
      datanominalpembiayaanmultijasa: [],
      datanominalpembiayaanqardh: [],
      datanominalpembiayaanbagihasil: [],
      datanominalpembiayaansewa: [],
      /* End Nominal Pembiayaan */

      lineOptions: {
        maintainAspectRatio: true,
        animation: false,
        tooltips: {
          callbacks: {
            label: function (t, d) {
              // console.log('t', t)
              var xLabel, yLabel
              // if (t.datasetIndex === 0) {
              //   xLabel = d.datasets[t.datasetIndex].label
              //   yLabel = t.yLabel
              //   return xLabel + ': ' + yLabel
              // } else {
              xLabel = d.datasets[t.datasetIndex].label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              yLabel =
                t.yLabel >= 1000
                  ? '' +
                    t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                  : '' + t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              return xLabel + ': ' + yLabel
              // }
            },
          },
        },
        scales: {
          // xAxes: [
          //   {
          //     display: true,
          //   },
          // ],
          // yAxes: [
          //   {
          //     stacked: true,
          //   },
          // ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (parseInt(value) >= 1000) {
                    return (
                      '' +
                      value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    )
                  } else {
                    return '' + value
                  }
                },
              },
            },
          ],
        },
      },
      barOptions: {
        tooltips: {
          callbacks: {
            label: function (t, d) {
              // console.log('t', t)
              var xLabel, yLabel
              // if (t.datasetIndex === 0) {
              //   xLabel = d.datasets[t.datasetIndex].label
              //   yLabel = t.yLabel
              //   return xLabel + ': ' + yLabel
              // } else {
              xLabel = d.datasets[t.datasetIndex].label
              yLabel =
                t.yLabel >= 1000
                  ? '' +
                    t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                  : '' + t.yLabel
              return xLabel + ': ' + yLabel
              // }
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (parseInt(value) >= 1000) {
                    return (
                      '' +
                      value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    )
                  } else {
                    return '' + value
                  }
                },
              },
            },
          ],
        },
      },

      dataChartLabaRugi: {
        labels: [],
        datasets: [],
      },
      dataChartJumlahTabungan: {
        labels: [],
        datasets: [],
      },
      dataChartTotalTabungan: {
        labels: [],
        datasets: [],
      },
      dataChartTotalAset: {
        labels: [],
        datasets: [],
      },
      /* Pembiayaan */
      dataChartPembiayaanmurabahah: {
        labels: [],
        datasets: [],
      },
      dataChartPembiayaanmultijasa: {
        labels: [],
        datasets: [],
      },
      dataChartPembiayaanqardh: {
        labels: [],
        datasets: [],
      },
      dataChartPembiayaanbagihasil: {
        labels: [],
        datasets: [],
      },
      dataChartPembiayaansewa: {
        labels: [],
        datasets: [],
      },
      /* End Pembiayaan */

      /* Nominal Pembiayaan */
      dataChartNominalPembiayaanmurabahah: {
        labels: [],
        datasets: [],
      },
      dataChartNominalPembiayaanmultijasa: {
        labels: [],
        datasets: [],
      },
      dataChartNominalPembiayaanqardh: {
        labels: [],
        datasets: [],
      },
      dataChartNominalPembiayaanbagihasil: {
        labels: [],
        datasets: [],
      },
      dataChartNominalPembiayaansewa: {
        labels: [],
        datasets: [],
      },
      /* End Nominal Pembiayaan */
    }
  },
  created() {
    this.datenow = lou.datenow()
    // var startYear = moment().startOf('year').format('YYYY-MM-DD')
    // var endYear = moment().endOf('year').format('YYYY-MM-DD')
    var lastMonth = moment().subtract(1, 'month').format('DD-MM-YYYY')
    var initlastmonth = moment(lastMonth, 'DD-MM-YYYY').subtract(11, 'month').format('DD-MM-YYYY')
    var endMonth2 = moment(lastMonth, 'DD-MM-YYYY').endOf('month').format('YYYY-MM-DD')
    var endMonth1 = moment(initlastmonth, 'DD-MM-YYYY').endOf('month').format('YYYY-MM-DD')
    this.tgl1 = endMonth1
    this.tgl2 = endMonth2
    this.inittgl1 = moment(endMonth1)
    this.inittgl2 = moment(endMonth2)
    this.getAllData()
  },
  methods: {
    moment,
    onChange(date, dateString, from) {
      // console.log(date, dateString)
      if (from === 'tgl1') {
        this.tgl1 = moment(dateString, 'MM-YYYY')
          .endOf('month')
          .format('YYYY-MM-DD')
      } else {
        this.tgl2 = moment(dateString, 'MM-YYYY')
          .endOf('month')
          .format('YYYY-MM-DD')
        this.getAllData()
      }
      // console.log('tgl1', this.tgl1)
      // console.log('tgl2', this.tgl2)
    },
    async getAllData() {
      var data = await lou.customUrlGet({ dari: this.tgl1, sampai: this.tgl2 })
      this.datalabarugi = data.data.labarugi
      this.datajumlahtabungan = data.data.jumlahtabungan
      this.datatotaltabungan = data.data.totaltabungan
      this.datatotalaset = data.data.totalaset

      this.datapembiayaanmurabahah = data.data.pembiayaan.murabahah
      this.datapembiayaanmultijasa = data.data.pembiayaan.multijasa
      this.datapembiayaanqardh = data.data.pembiayaan.qardh
      this.datapembiayaanbagihasil = data.data.pembiayaan.bagihasil
      this.datapembiayaansewa = data.data.pembiayaan.sewa

      this.datanominalpembiayaanmurabahah = data.data.nominalpembiayaan.murabahah
      this.datanominalpembiayaanmultijasa = data.data.nominalpembiayaan.multijasa
      this.datanominalpembiayaanqardh = data.data.nominalpembiayaan.qardh
      this.datanominalpembiayaanbagihasil = data.data.nominalpembiayaan.bagihasil
      this.datanominalpembiayaansewa = data.data.nominalpembiayaan.sewa

      this.pilihsandikantor(0, this.dataChartOptions.labarugi)
      this.pilihsandikantor(0, this.dataChartOptions.jumlahtabungan)
      this.pilihsandikantor(0, this.dataChartOptions.totaltabungan)
      this.pilihsandikantor(0, this.dataChartOptions.totalaset)
      this.pilihsandikantorpembiayaan(0)
      this.pilihsandikantornominalpembiayaan(0)
    },
    pilihsandikantorpembiayaan(i) {
      this.pilihsandikantor(i, this.dataChartOptions.pembiayaanmurabahah)
      this.pilihsandikantor(i, this.dataChartOptions.pembiayaanmultijasa)
      this.pilihsandikantor(i, this.dataChartOptions.pembiayaanqardh)
      this.pilihsandikantor(i, this.dataChartOptions.pembiayaanbagihasil)
      this.pilihsandikantor(i, this.dataChartOptions.pembiayaansewa)
    },
    pilihsandikantornominalpembiayaan(i) {
      this.pilihsandikantor(i, this.dataChartOptions.nominalpembiayaanmurabahah)
      this.pilihsandikantor(i, this.dataChartOptions.nominalpembiayaanmultijasa)
      this.pilihsandikantor(i, this.dataChartOptions.nominalpembiayaanqardh)
      this.pilihsandikantor(i, this.dataChartOptions.nominalpembiayaanbagihasil)
      this.pilihsandikantor(i, this.dataChartOptions.nominalpembiayaansewa)
    },
    pilihsandikantor(index, { fromarray, toarray, selectname, fill = false, dynamicColor = false, bgcolorOpacity = '1', bordercolorOpacity = '1' }) {
      var ndata = this[fromarray][index]
      this.select[selectname] = index
      this[toarray].labels = []
      ndata.labels.forEach((element) => {
        var label = moment(element, 'YYYY-MM-DD').format('DD-MM-YYYY')
        this[toarray].labels.push(label)
      })
      this[toarray].datasets = []
      ndata.datasheet.forEach((element, index) => {
        var dataset = {
          label: element.name,
          fill: fill,
          lineTension: 0.1,
          borderWidth: 1,
          borderColor: dynamicColor
            ? lou.dynamicColors(index, bordercolorOpacity)
            : 'rgb(' + element.color + ', ' + bordercolorOpacity + ')',
          backgroundColor: dynamicColor
            ? lou.dynamicColors(index, bgcolorOpacity)
            : 'rgb(' + element.color + ', ' + bgcolorOpacity + ')',
          pointRadius: 2,
          data: element.data,
        }
        this[toarray].datasets.push(dataset)
      })
      this.componentKey += 1
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style></style>
